import { useFormik } from "formik";
import * as Yup from 'yup';
import useStyles from "./styles";
import contacto_formulario from '../../../assets/images/contacto_formulario.png';
import { Button, TextField } from "@mui/material";
import { ClientServices } from "../../../services/client.service.ts";
import useAlertStore from "../../../state/alertState";

const ContactContent = () => {
  const classes = useStyles();
  const { showAlert } = useAlertStore();

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      companyName: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Campo obligatorio'),
      email: Yup.string().email('Email no válido').required('Campo obligatorio'),
      companyName: Yup.string().required('Campo obligatorio'),
    }),
    onSubmit: async (values: any) => {

      await ClientServices.getInstance().getConsultaEmail(values.email).then(async (data) => {
        if (data.respuesta.length === 0) {
          const createdDate = new Date().toISOString();
          const bodyAxios = {
            ...values,  
            emailClient: `kgerman@klausgermanph.com`,  //correo de klaus
            emailAx: `servicios@axeleratum.com`, //correo de soporte de ax
            createdDate: createdDate, 
            lastModifiedDate: createdDate,
          };
          await ClientServices.getInstance().postConsultaEmail(bodyAxios);
        } else {
          showAlert(
            'failed',
            'Deberá esperar al menos 30 minutos antes de volver a solicitar información.',
            true
          );
        }
      });
      
    },
  });

  return (
    <div>
      <h1 className={classes.heading} style={{ textAlign: 'center' }}>Queremos resolver tus dudas</h1>
      <section id="contacto" className={classes.section}>
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
          <TextField
            fullWidth
            label="Nombre"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name ? String(formik.errors.name) : ''}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Correo"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email ? String(formik.errors.email) : ''}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Nombre de la empresa"
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.companyName && Boolean(formik.errors.companyName)}
            helperText={formik.touched.companyName && formik.errors.companyName ? String(formik.errors.companyName) : ''}
            margin="normal"
          />
          <Button color="primary" variant="contained" type="submit" style={{ marginTop: '16px' }}>
            Enviar
          </Button>
        </form>
        <div className={classes.imageContainer}>
          <img src={contacto_formulario} alt="Descripción de la imagen" className={classes.image} />
        </div>
      </section>
    </div>
  );
};

export default ContactContent;
